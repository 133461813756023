<template>
  <v-list-item link title="Dashboard" @click="redirectToDashboard">
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-monitor-dashboard</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">Dashboard FL</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuDashboard",
  props: {
    superAdmin: Boolean
  },
  methods: {
    redirectToDashboard() {
      window.location.href = "http://dashboardficheliaison-front.kubingresses.planett.net/";
    }
  }
}
</script>

<style scoped>

</style>